'use client';

import Prism from 'prismjs';
import { type FC, useEffect } from 'react';

import { useHtmlParse } from '@/utils/htmlParse';

type BlogContentProps = {
  content: {
    html: string;
  };
};

export const BlogContent: FC<BlogContentProps> = ({ content }) => {
  const parsedContent = useHtmlParse(content.html);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return parsedContent ? (
    <article className="prose mx-auto max-w-5xl overflow-x-auto lg:prose-lg xl:prose-xl">
      {parsedContent}
    </article>
  ) : null;
};
