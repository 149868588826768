'use client';

import ReactHtmlParser from 'html-react-parser';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';

const loadPrism = async () => {
  const Prism = (await import('prismjs')).default;
  await import('prismjs/components/prism-java');
  await import('prismjs/components/prism-nginx');
  await import('prismjs/components/prism-javascript');
  await import('prismjs/components/prism-dart');
  await import('prismjs/plugins/autoloader/prism-autoloader');
  await import('prismjs/plugins/toolbar/prism-toolbar');
  await import('prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard');
  await import(
    'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace'
  );
  await import('prismjs/plugins/match-braces/prism-match-braces');
  await import('prismjs/plugins/previewers/prism-previewers');
  return Prism;
};

export const cleanHtml = (htmlString: string) => {
  return htmlString.replace(/<(\w+)([^>]*)>(&nbsp;|\s)*<\/\1>/g, '');
};

export const useHtmlParse = (content: string) => {
  const [parsedContent, setParsedContent] = useState<React.ReactNode>(null);

  const parsedHtml = useMemo(() => {
    return ReactHtmlParser(cleanHtml(content));
  }, [content]);

  useEffect(() => {
    const highlightContent = async () => {
      const Prism = await loadPrism();
      Prism.highlightAll();
    };

    if (typeof window !== 'undefined') {
      highlightContent();
    }

    setParsedContent(parsedHtml);
  }, [parsedHtml]);

  return parsedContent;
};
