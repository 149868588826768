import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import { Button } from '../Button';
import {
  CheckIcon,
  CopyIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from '../Icons';

const iconClass = 'px-2.5 py-3 rounded-lg  bg-footer text-primary-700';

const hoverClass = 'hover:shadow-md hover:shadow-blue-200 hover:text-blue-400';

export const SocialShare = () => {
  const [shareUrl, setShareUrl] = useState<string>('');
  const [copied, setCopied] = useState<boolean>(false);
  const [clientReady, setClientReady] = useState(false);

  useEffect(() => {
    setClientReady(true);
    setShareUrl(document.URL);
  }, []);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
    return undefined;
  }, [copied]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setCopied(true);
      })
      .catch(() => {});
  };

  return (
    <div className="flex flex-col items-center gap-3">
      <Button
        variant="text"
        startIcon={
          copied ? (
            <CheckIcon className="h-6 w-6 text-green-500" />
          ) : (
            <CopyIcon />
          )
        }
        label={undefined}
        className={clsx('rounded-lg bg-footer p-2.5 font-semibold', hoverClass)}
        onClick={handleCopy}
      />
      {clientReady && (
        <>
          <TwitterShareButton url={shareUrl}>
            <Button
              variant="text"
              startIcon={<TwitterIcon />}
              label={undefined}
              className={clsx(iconClass, hoverClass)}
            />
          </TwitterShareButton>
          <FacebookShareButton url={shareUrl}>
            <Button
              variant="text"
              startIcon={<FacebookIcon />}
              label={undefined}
              className={clsx(iconClass, hoverClass)}
            />
          </FacebookShareButton>
          <LinkedinShareButton url={shareUrl}>
            <Button
              variant="text"
              startIcon={<LinkedinIcon />}
              label={undefined}
              className={clsx(iconClass, hoverClass)}
            />
          </LinkedinShareButton>
        </>
      )}
    </div>
  );
};
